.NotFound {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f0f0f0;
}

.NotFound-container {
    max-width: 600px;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.NotFound h1 {
    font-size: 96px;
    margin-bottom: 24px;
}

.NotFound p {
    font-size: 20px;
    margin-bottom: 20px;
}

.NotFound a:hover {
    background-color: #0056b3;
}
