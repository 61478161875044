@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard-dynamic-subset.css");
/* reset */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,textarea,button,pre {
    margin:0;
    padding:0
}
fieldset,img {
    border:0 none;
    vertical-align:top;
}
dl,ul,ol,li {
    list-style:none
}
blockquote, q {
    quotes: none
}
blockquote:before, blockquote:after,q:before, q:after {
    content:none
}
input,select,textarea,button {
    vertical-align:middle
}
button {
    border:0 none;
    background-color:transparent;
    cursor:pointer
}
body {
    background:#fff
}
body,th,td,input,select,textarea,button,pre{
    font-size:12px;
    line-height:1.5;
    font-family:"Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    color:#333
}
/* color값은 디자인가이드에 맞게사용 */
a {
    color:#333;
    text-decoration:none
}
a:active, a:hover {
    text-decoration:none;
}
address,caption,cite,code,dfn,em,var {
    font-style:normal;
    font-weight:normal
}

html,body{
    font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    height: 100%;
}

#root { /* 또는 전체 페이지를 감싸는 최상위 컨테이너 */
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.hidden{
    display: none;
    visibility: hidden;
    font-size: 0;
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0
}
.body{
    min-height: 100vh;
    position: relative;
}
/*헤더*/
.header{
    position:fixed;
    padding: 0 0 42px 50px;
    box-sizing: border-box;
    top: 0;
    background: #ffffff;
    width: 100%;
    z-index: 200;
    border-bottom:1px solid #ececec;
}

.wrap{
    position: relative;
    min-height: 100vh;
}

/*#header */
.h1_logo {
    width: 130px;
    text-align:left;
    padding:30px 0 30px 0;
    height: 42px;
}
/*#header */
.h1_logo img{
    height: 100%;
    display: block;
    min-height: 42px;
}
/*메인*/
.main {
    box-sizing: border-box;
    padding: 135px 50px; /* 상단 및 좌우 패딩 조정 */
    width: 100%; /* 너비를 뷰포트의 100%로 설정 */
    max-width: 1200px;
    margin: 0 auto; /* 좌우 마진을 자동으로 설정하여 가운데 정렬 */
}

.main_text h2{
    font-size: 22px;
    font-weight: 800
}
.main_text p{
    text-align: left;
    font-size: 18px;
    line-height: 1.2em;
    word-break: keep-all;
    margin: 20px 0;
}
.main .form{
    margin: 0 auto 15px;
    width: 100%;
    padding: 20px 50px 30px;
    box-sizing: border-box;
    background-color: #f4f4f4
}
.main .form li{
    margin: 10px auto 0;
    width: 280px;
    display: flex;
    align-items: center
}
.main .form .title{
    font-weight: 800;
    width: 30%;
    padding-right: 8px;
    box-sizing: border-box;
}
.main .form .title, .main .form .text{
    display: inline-block;
    font-size: 18px;
    text-align: left;
}
.main .form input{
    border: 1px solid #555;
    padding: 5px 10px;
    width: 70%
}
.main .form .btn{
    margin: auto;
    display: block;
    padding: 14px;
    box-sizing: border-box;
    background-color: #011E41;
    color: #fff;
    text-align: center;
    font-size: 18px;
    box-shadow: none;
    border: none;
    width: 100%
}
.main .form .btn:hover{
    opacity: 0.7;
}
.main .info{
    font-size: 16px;
    color: #777;
}
footer, .footer_wrap{
    position:absolute;
    bottom: 0;
    width: 100%
}
.footer {
    overflow: hidden;
    padding: 0 50px;
    box-sizing: border-box;
    min-width: 1820px;
    background: linear-gradient(135deg, transparent 25px, #0f0f0f 0);
    position: absolute;
}
/* 풋터 메뉴 */
.foot_list ul{
    overflow:hidden;
    width:1200px;
    margin:0 auto;
}
.foot_list ul li{
    float:left;
    padding:15px 0 15px 0;
}
.foot_list ul li a{
    padding:0 15px 0 14px;
    color:#717171;
    border-left:1px solid #e0e0e0;
}
.foot_list ul li:first-child a{
    padding-left:0;
    border-left:0 none;
}
.foot_top_wrap{
    width: 100%;
    margin: auto;
    max-width: 1820px;
    padding: 70px 0 20px;
}
.foot_top_wrap::after{
    content: '';
}
.foot_logo{
    display:inline-block;
    width:160px;
}
/* 회사 정보 */
.foot_cont{
    overflow:visible;
    position: relative;
    padding:28px 0;
    color:#fff;
    line-height:1.8;
    font-family: 'Poppins', 'Noto Sans KR', sans-serif;
    font-weight: 200;
    letter-spacing: .5px;
    width: 100%;
    max-width: 1820px;
    box-sizing: border-box;
    height: 126px;
}
.foot_cont .foot_info{
    display:inline-block;
    float:left;
}
.foot_cont .foot_info_list{
    overflow:hidden;
    width:100%;
}
.foot_cont .foot_info_list dl{
    float:left;
}
.foot_cont .foot_info_list dt{
    float:left;
    font-size: 13px;
    font-weight: 600;
}
.foot_cont .foot_info_list dd{
    float:left;
    padding:0 15px 0 8px;
    opacity: 0.6;
    font-size: 14px;
    font-weight: 600;
}
.foot_cont .copyright{
    opacity: 0.3;
    font-size: 12px;
    margin-top: 30px;
    position: absolute;
    right: 0;
    font-weight: 600;
}
.foot_cont .foot_info_list a{
    color: #fff;
}
.foot_cont address{
    font-weight: 600;
}
/* 패밀리사이트 나열 */
.familysite{
    display: block;
    float: right;
}
.familysite li{
    display: inline-block;
}
.familysite li a{
    color: #fff;
    font-size: 15px;
    padding-left: 14px;
    font-family: 'Poppins', 'Noto Sans KR', sans-serif;
    font-weight: 600;
}

/* logo.js에 대한 스타일 */
#logo .st0 {
    fill: #011E41;
}
