@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard-dynamic-subset.css");
@charset "utf-8";
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,textarea,button,pre {
    margin:0;
    padding:0
}
body,th,td,input,select,textarea,button,pre{
    font-family:"Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    color:#333
}
html,body{
    height: 100%;
    font-size: 1em
}
body {
    width:100%;
    -webkit-overflow-scrolling:touch;
}
.header_wrap header {
    top: 0px;
    padding-Top: 0px;
    width:100%;
    min-width:320px;
    background:#fff;
    z-index:300;
}

.header_wrap header .header_box {
    border-bottom:1px solid #cbcbcb;
    height: 50px;
    box-sizing: border-box;
}
.header_wrap header .header_box .h_logo {
    width:90px;
    padding:10px 0 10px 0;
    margin:0 0 0 20px;
    line-height:0;
    text-align:center;
}
.header_wrap header .header_box .h_logo a {
    font-size:0;
    line-height:0;
}
.header_wrap header .header_box .h_logo img {
    width:100%;
}

.wrap{
    position: relative;
    min-height: 100%;
}

/*메인*/
.main{
    box-sizing: border-box;
    padding:25px 30px 50px;
    margin: auto;
    /*min-height: 138vh; !* 최소 높이를 뷰포트의 100%로 설정 *!*/
    font-size: 1.3em;
}

.main_text h2{
    font-size: 1.2em;
    font-weight: 800
}
.main_text p{
    text-align: left;
    font-size: 1em;
    line-height: 1.4em;
    word-break: keep-all;
    margin: 20px 0;
}
.main .form{
    margin: 0 auto 20px;
    width: 100%;
    padding: 20px 10px 30px;
    box-sizing: border-box;
    background-color: #f4f4f4
}
.main .form li{
    margin: 10px auto 0;
    width: 80%;
    display: flex;
    align-items: center
}
.main .form .title{
    font-weight: 800;
    width: 30%;
    padding-right: 8px;
    box-sizing: border-box;
}
.main .form .title, .main .form .text{
    display: inline-block;
    font-size: 1em;
    text-align: left;
}
.main .form input{
    border: 1px solid #555;
    padding: 5px 10px;
    width: 70%
}
.main .form .btn{
    margin: auto;
    display: block;
    padding: 14px;
    background-color: #011E41;
    color: #fff;
    text-align: center;
    font-size: 1em;
    box-shadow: none;
    border: none;
    width: 100%
}
.main .info{
    font-size: 0.9em;
    color: #777;
    word-break: keep-all;
}
.footer{
    height: 360px;
    margin:0;
    padding: 0px 0px 20px;
    min-width: 100%;
    position: absolute;
}

.footer footer .ft_info2_box {
    background:linear-gradient(135deg, transparent 25px, #0f0f0f 0);
    padding: 10% 10% 0;
    box-sizing: border-box;
}
.footer footer .ft_info2_box .ft_menu {
    display:table;
    width:100%;
}
.footer footer .ft_info2_box .ft_menu li {
    display:table-cell;
    width:25%;
}
.footer footer .ft_info2_box .ft_menu li a {
    display:block;
    padding:18px 0 18px 0;
    font-size:0.8em;
    color:#d1d1d1;
    text-align:left;
    letter-spacing:-1px;
}
.footer footer .ft_info2_box .ft_menu li a.privacy {
    color:#fff;
    font-weight:bold;
}
.footer footer .ft_info2_box .ft_address {
    padding:0;
    text-align:left;
    font-size:1.1em;
    color:#878787;
    word-break:keep-all;
    line-height: 1.8em;
}
.footer footer .ft_info2_box .ft_address strong{
    color: #fff;
    margin-right: 3px;
    font-weight: 300;
}
/* 카피라이터 */
.footer footer .ft_copy {
    padding:15px 0 30px 0;
    color:#4c4c4c;
    font-size:1.1em;
    text-align:left;
    font-style:normal;
}
/* 패밀리사이트 */
.familysite{
    margin-top: 14px;
}
.familysite li{
    display: inline-block;
    padding-right: 10px;
}
.familysite li a{
    color: #fff;
    font-size:1.1em;
    text-decoration: none;
}

._self{
    /*width: 130px;*/
    display: block;
    width: 30%;
    margin-bottom:20px;
}

.h_logo {
    width: 130px;
    text-align:left;
    padding:30px 0 30px 0;
    height: 42px;
}

/* logo.js에 대한 스타일 */
#logo .st0 {
    fill: #011E41;
}

/* logo_w.js에 대한 스타일 */
#logo_w .st0 {
    fill: #FFFFFF;
}
