.pdf-viewer-container {
    padding-top: 60px; /* 고정된 메뉴에 의해 가려지지 않도록 조정 */
    display: flex;
    flex-direction: column;
    align-items: center; /* PC에서는 중앙 정렬 */
    width: 100%;
}

@media (max-width: 768px) { /* 모바일 화면 사이즈에 대응 */
    .pdf-viewer-container {
        align-items: flex-start; /* 모바일에서는 왼쪽 정렬 */
    }
}

/* 전체 화면 오버레이 */
.pdf-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10; /* 페이지 컨테이너 아래에 위치하도록 낮은 z-index */
}

.page-container {
    position: relative;
    width: 100%; /* 원하는 너비로 설정 (예: 80%로 줄임) */
    z-index: 20;
    display:flex; /* 페이지 컨테이너를 flex 컨테이너로 설정 */
    justify-content: center; /* 페이지를 수평 중앙으로 정렬 */
    margin: auto;
}
@media (max-width: 768px) {
    .page-container {
        justify-content: flex-start; /* 모바일에서는 페이지를 왼쪽으로 정렬 */
    }
}

.controls {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #333333;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 30;
}

.pdf-file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30%;
    text-align: left;
    font-weight: bold;
    color: white;
}
.page-info {
    flex-shrink: 0; /* 페이지 정보가 축소되지 않도록 유지 */
}

.pdf-file-name, .page-info {
    position: absolute; /* 절대 위치를 사용하여 양쪽 끝에 배치 */
}

.zoom-controls {
    display: flex;
    justify-content: center; /* 중앙 정렬로 변경 */
    align-items: center;
    flex-grow: 1; /* 줌 컨트롤이 남은 공간을 모두 사용하도록 변경 */
    color: white;
}

.zoom-controls button{
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.zoom-controls span {
    margin: 0 10px; /* 버튼과 페이지 정보 간 여백 유지 */
}

#zoom-in {
    font-size: 20px; /* 글꼴 크기 조정 */
    cursor: pointer; /* 커서를 손가락 모양으로 변경 */
    border: none; /* 테두리 없앰 */
}

#zoom-out {
    font-size: 20px; /* 글꼴 크기 조정 */
    cursor: pointer; /* 커서를 손가락 모양으로 변경 */
    border: none; /* 테두리 없앰 */
}

.controls button {
    margin: 0 10px;
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, 0); /* 반투명한 검은색 배경 */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.controls button:hover {
    background-color: rgba(0, 0, 0, 0.2); /* 반투명한 검은색 배경 */;
}
